import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'LoginPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			loginRequest: {},
			errorBag: {},
			dismissSecs: 15,
			dismissCountDown: 0,
			showDismissibleAlert: false
			// title: process.env.VUE_APP_TITLE
		};

	},
	props: {
		msg: String
	},
	methods: {
		showAlert() {

			this.dismissCountDown = this.dismissSecs;
		},

		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown
		},
		login() {
			this.ss.login(this.loginRequest)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('usuario', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						var usuario = JSON.parse(localStorage.getItem('usuario'));
						this.auth = usuario;

						if(this.auth.UnidadAcademica == null || this.auth.ApellidoPaterno == null || this.auth.ApellidoMaterno == null || this.auth.Nombres == null || this.auth.email == null || this.auth.CorreoEmi == null || this.auth.CI == null){
							this.$router.push('/Usuario');
						}else{
							this.$router.push('/Entregas');
						}
						if(this.auth.Rol == 9){
							this.$router.push('/Proyecto');

						}
						this.$router.go();
						/*var usuario = JSON.parse(localStorage.getItem('usuario'));
						console.log(usuario)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
		},
		authenticate() {
			console.log("login")
			// console.log("office",this.$msal.isAuthenticated);
			if (!this.$msal.isAuthenticated()) {
				// console.log("office 2",this.$msal.signIn());

				this.$msal.signIn();
			}
		}
	},
	mounted() {
		this.showAlert();
	/* 	this.$bvToast.toast(
			'',
			{
				title: 'Si ingresa por primera vez al Sistema, hagalo presionando el boton "Iniciar Sesión co Office 365, posteriormente comuniquese con el Administrador para que le asigne el Rol correspondiente"',
				variant: 'info',
				autoHideDelay: 10000
			}
		) */

		var usuario = JSON.parse(localStorage.getItem('usuario'));
		console.log("usuario",usuario);

		if (usuario) {
			console.log("existe el usuario");
			localStorage.clear();
		}
		if (this.$msal.isAuthenticated()) {
			console.log("esta autenticado",this.$msal.data);

			// console (this.$msal.data.user);
			let loginTokenRequest = {
				"token": this.$msal.data.user.accountIdentifier,
				"email": this.$msal.data.user.userName,
			};

			console.log("loginTokenRequest",loginTokenRequest);

			this.ss.loginToken365(loginTokenRequest)
				.then(
					(result) => {
						// console.log(result, 'primer check');
						localStorage.setItem('usuario', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						// this.$router.push('/Entregas');
						// this.$router.reload()
						var usuario = JSON.parse(localStorage.getItem('usuario'));
						this.auth = usuario;

						if(this.auth.UnidadAcademica == null || this.auth.ApellidoPaterno == null || this.auth.ApellidoMaterno == null || this.auth.Nombres == null || this.auth.email == null || this.auth.CorreoEmi == null || this.auth.CI == null){
							this.$router.push('/Usuario');
						}else{
							this.$router.push('/Entregas');
						}
						if(this.auth.Rol == 9){
							this.$router.push('/Proyecto');

						}
						location.reload();
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					let usuario = {
						Usuario:this.$msal.data.user.idToken.name,
						email:this.$msal.data.user.idToken.preferred_username
						
					}
					//crear a la persona
					// console.log("entro al error hhfghfghfgh")
					console.log("usuario error",usuario);

					this.ss.storeLogin(usuario)
						.then(
							(result) => {
								console.log(result, "usuario creado");
								this.ss.loginToken365(loginTokenRequest)
								.then(
									(result) => {
										// console.log(result, 'primer check');
										localStorage.setItem('usuario', JSON.stringify(result.data.data));
										localStorage.setItem('token', JSON.stringify(result.data.token));
										// this.$router.push('/Entregas');
										// this.$router.reload()
										var usuario = JSON.parse(localStorage.getItem('usuario'));
										this.auth = usuario;

										if(this.auth.UnidadAcademica == null || this.auth.ApellidoPaterno == null || this.auth.ApellidoMaterno == null || this.auth.Nombres == null || this.auth.email == null || this.auth.CorreoEmi == null || this.auth.CI == null){
											this.$router.push('/Usuario');
										}else{
											this.$router.push('/Entregas');
										}
										if(this.auth.Rol == 9){
											this.$router.push('/Proyecto');

										}
										location.reload();
									}
								)
								.catch((error) => {
									console.log(error)
								});
							}
						)
						.catch((error) => {
							this.errorBag = error.response.data.errors;
							this.$bvToast.toast(
								'Problema al Iniciar Sesion',
								{
									title: 'Error',
									variant: 'danger',
									autoHideDelay: 5000
								}
							)
						});
					//this.ss.loginToken
					// console.log(usuario, 'hola');
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
					//this.$msal.signOut();
				});

				

		} else {
			console.log("no esta logeeadoooo")
		}
	}
}